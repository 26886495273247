import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import FormikInput from 'styles/Signup/FormikInput';
import FormikPasswordInput from 'styles/Signup/FormikPasswordInput';
import FormikCheckbox from 'styles/Signup/FormikCheckbox';
import http from 'components/util/http';
import { useNotificationContext } from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'classnames';
import useSessionStorage from 'hooks/useSessionStorage';
import Progress from 'components/Progress';
import useLocalizedMarketingPageLinks from '../../hooks/useLocalizedMarketingPageLinks';

YupPassword(Yup);

type FormValues = {
  email: string;
  password: string;
  password_confirm: string;
  agb_subscriber: boolean;
  data_share_subscriber: boolean;
  data_storage_subscriber: boolean;
  is_newsletter_subscriber: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Bitte geben Sie Ihre E-Mail-Adresse ein'),
  password: Yup.string()
    .required('Passwort ist ein Pflichtfeld')
    .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen')
    .minNumbers(1, 'Mindestens eine Zahl'),
  password_confirm: Yup.string()
    .required('Passwort wiederholen ist ein Pflichtfeld')
    .oneOf([Yup.ref('password'), null], 'Die Passwörter müssen übereinstimmen')
    .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen')
    .minNumbers(1, 'Mindestens eine Zahl'),
  agb_subscriber: Yup.bool().oneOf([true]),
  data_share_subscriber: Yup.bool().oneOf([true]),
  data_storage_subscriber: Yup.bool().oneOf([true]),
});

export default function Email() {
  const { showNotification, clearNotification } = useNotificationContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { getItem } = useSessionStorage();
  const { privacy_policy, general_terms } = useLocalizedMarketingPageLinks();

  const handleSubmit = async (formValues: FormValues) => {
    const ailments = getItem('ailments');
    const customAilment = getItem('customAilment');
    const personal = getItem('personal');
    const patientAilments = customAilment !== '' ? [...ailments, customAilment] : ailments;
    const data = { ...formValues, complaints: patientAilments, ...personal };

    try {
      setLoading(true);
      clearNotification();
      await http.post('medical/validate/', { ...data, step: 3 });
      await http.post('medical/register/', data);

      window.dataLayer.push({
        event: 'patienten-register',
        eventStatus: 'step-2',
      });

      navigate('/register/complete');
    } catch (error: any) {
      let msg = 'Etwas ist schiefgelaufen';
      if (error) {
        msg = (Object.values(error)[0] as any)[0];
      }

      showNotification(
        {
          type: 'error',
          text: msg,
        },
        15000,
      );

      window.dataLayer.push({
        event: 'patienten-register',
        eventStatus: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <div className="max-w-lg m-auto">
        <Progress
          currentStep={1}
          steps={[
            {
              title: 'In nur wenigen Schritten zur persönlichen Therapie',
              description:
                'Erstellen Sie Ihr kostenloses Enmedify-Konto und nutzen Sie alle Vorteile unseres digitalen Therapieprozesses.',
            },
            {
              title: 'In nur wenigen Schritten zur persönlichen Therapie',
              description:
                'Erstellen Sie Ihr kostenloses Enmedify-Konto und nutzen Sie alle Vorteile unseres digitalen Therapieprozesses.',
            },
          ]}
        />
      </div>

      <Formik
        initialValues={{
          email: '',
          password: '',
          password_confirm: '',
          agb_subscriber: false,
          data_share_subscriber: false,
          data_storage_subscriber: false,
          is_newsletter_subscriber: false,
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <div className="max-w-lg m-auto">
              <h3 className="text-swopa-primary-dark-blue font-radikal mb-4 text-base text-center">
                Ihre Zugangsdaten
              </h3>

              <div className="mb-4">
                <FormikInput
                  label="E-Mail-Adresse"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="mustername@mail.de"
                  error={!!(formikProps.touched.email && formikProps.errors.email)}
                  errorMessage={formikProps.errors.email}
                />
              </div>

              <div className="grid grid-cols-2 gap-2 mb-4">
                <FormikPasswordInput
                  label="Passwort"
                  name="password"
                  id="password"
                  placeholder="min. 8 Zeichen"
                  error={!!(formikProps.touched.password && formikProps.errors.password)}
                  errorMessage={formikProps.errors.password}
                />
                <FormikPasswordInput
                  label="Passwort wiederholen"
                  name="password_confirm"
                  id="password_confirm"
                  placeholder="min. 8 Zeichen"
                  error={
                    !!(formikProps.touched.password_confirm && formikProps.errors.password_confirm)
                  }
                  errorMessage={formikProps.errors.password_confirm}
                />
              </div>

              <span className="text-[#898D8D] text-xs block mb-14">
                Ihr Passwort muss aus mindestens 8 Zeichen bestehen und eine Zahl beinhalten.
              </span>
            </div>

            <div className="max-w-2xl m-auto">
              <hr className="w-full border border-dashed border-swopa-primary-grey-light mt-16 mb-10" />

              {/* terms & conditions */}
              <h3 className="text-swopa-primary-dark-blue font-radikal mb-4 text-base text-center">
                AGB & Datenschutz
              </h3>

              <div className="text-swopa-primary-dark-blue mb-16">
                <FormikCheckbox
                  type="checkbox"
                  name="agb_subscriber"
                  id="agb_subscriber"
                  label={
                    <span>
                      Ich habe die{' '}
                      <a
                        target="_blank"
                        href={general_terms}
                        className="text-swopa-accent-green underline"
                      >
                        AGB
                      </a>{' '}
                      zur Kenntnis genommen
                    </span>
                  }
                  error={
                    !!(formikProps.touched.agb_subscriber && formikProps.errors.agb_subscriber)
                  }
                />

                <p className="mb-5">
                  Ich erkläre mein jederzeit widerrufbares Einverständnis in die Verarbeitung{' '}
                  <b>meiner personenbezogenen Daten</b> (insb. auch <b>Gesundheitsdaten</b>) zu den
                  nachfolgenden Zwecken (siehe{' '}
                  <a
                    target="_blank"
                    href={privacy_policy}
                    className="text-swopa-accent-green underline"
                  >
                    Datenschutzerklärung
                  </a>
                  ):
                </p>

                <FormikCheckbox
                  type="checkbox"
                  name="data_share_subscriber"
                  id="data_share_subscriber"
                  label="zur Terminbuchung und zur Vermittlung von Ärzt:innen"
                  error={
                    !!(
                      formikProps.touched.data_share_subscriber &&
                      formikProps.errors.data_share_subscriber
                    )
                  }
                />
                <FormikCheckbox
                  type="checkbox"
                  name="data_storage_subscriber"
                  id="data_storage_subscriber"
                  label="zur anonymisierten statistischen Auswertung"
                  error={
                    !!(
                      formikProps.touched.data_storage_subscriber &&
                      formikProps.errors.data_storage_subscriber
                    )
                  }
                />
                <FormikCheckbox
                  type="checkbox"
                  name="is_newsletter_subscriber"
                  id="is_newsletter_subscriber"
                  label="zum Erhalt des Enmedify E-Mail Newsletters (optional)"
                />
              </div>
            </div>

            <div className="md:px-6 mb-8 w-full text-center relative">
              {loading && (
                <Spinner className="fill-swopa-primary-dark w-5 h-5 absolute top-4 left-[50%] ml-2.5 z-10" />
              )}
              <button
                className={classNames(
                  'bg-swopa-accent-green hover:bg-swopa-accent-green-hover text-swopa-primary-dark uppercase p-4 w-full flex items-center justify-center rounded mb-4 font-radikal text-sm',
                  {
                    'opacity-50': loading,
                  },
                )}
                type="submit"
                disabled={loading}
              >
                Fertig stellen
              </button>
              <a
                href="/register/personal"
                className="text-swopa-primary-grey text-sm underline cursor-pointer"
              >
                Zurück
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
