import { useState } from 'react';
import classNames from 'classnames';
import { useNotificationContext } from '../components/Notification';
import {
  acceptedFileTypes,
  acceptedFileTypesDescription,
  inputAcceptedFileTypes,
} from '../constants/acceptedFileTypes';

type FileInputProps = {
  label?: string;
  id: string;
  onChange?: (file: File) => void;
};

export default function FileInput({ label, id, onChange }: FileInputProps) {
  const [file, setFile] = useState<File | null>(null);
  const notification = useNotificationContext();

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const valid = acceptedFileTypes.find((x) => x == file.type);

      if (valid) {
        setFile(file);
        if (onChange) onChange(file);
      } else {
        notification.showNotification(
          { type: 'error', text: ` Nicht unterstützter Dateityp: ${file.type}.` },
          5000,
        );
      }
    }
  };

  return (
    <div className="max-w-md">
      {label && (
        <label className="block mb-2 text-sm font-medium text-swopa-primary-dark-blue">
          {label}
        </label>
      )}
      <label
        htmlFor={id}
        className="flex items-center w-full text-xs text-swopa-secondary-grey border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none h-9"
      >
        <div className="h-full bg-swopa-primary-dark-blue w-fit flex items-center px-4 rounded-l-lg">
          <span className="text-white text-lg">Datei auswählen</span>
        </div>
        <span
          className={classNames('ml-3', {
            'text-swopa-primary-dark-blue': file,
            'text-swopa-secondary-grey': !file,
          })}
        >
          {file ? file.name : 'Keine Datei ausgewählt'}
        </span>
        <input
          id={id}
          className="hidden"
          type="file"
          accept={inputAcceptedFileTypes}
          onChange={onFileChange}
        />
      </label>
      <span className="text-xs text-swopa-secondary-grey mt-1">{acceptedFileTypesDescription}</span>
    </div>
  );
}
