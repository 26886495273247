import useLocalizedServicePage from 'hooks/useLocalizedServicePage';
import { Link } from 'react-router-dom';

const RegistrationComplete = () => {
  const email = useLocalizedServicePage();

  console.log("EEE", email);

  return (
    <div className="text-lg">
      <h1 className="text-xl mb-2">Vielen Dank für Deine Registrierung!</h1>
      <p className="mb-4">
        Um Dein Konto vollständig zu aktivieren, bestätige bitte Deine Registrierung, indem Du auf den Link in der Bestätigungs-E-Mail klickst, die wir Dir soeben gesendet haben.
      </p>
      <div className="mb-4 font-bold">
      Tipp:
      </div>
      <ul className="list-disc">
        <li>Schau auch im Spam-Ordner nach, falls Du die E-Mail nicht in Deinem Posteingang findest.</li>
        <li>Alternativ kannst Du die E-Mail erneut anfordern</li>
      </ul>
      <br/>
      <a href="/login" className="block mb-4 text-swopa-primary-dark-blue font-bold cursor-pointer">
        Zurück zur Anmeldung
      </a>
      <a
        href="/aktivierung-erneut-senden"
        className="block mb-4 text-swopa-primary-dark-blue font-bold cursor-pointer"
        >
        Aktivierungsmail erneut senden
      </a>
      <p className="mb-4">
        Bei Fragen hilft Dir unser Team gerne weiter: <a href="mailto:${email}">{email.contact_email}</a>
      </p>
    </div>
  );
};

export default RegistrationComplete;
