import { Navigate, Route, Routes } from 'react-router-dom';
import ScrollToTop from 'components/util/ScrollToTop';
import { AuthProvider, useAuthContext } from 'components/util/useAuth';
import Therapy from 'pages/Therapy';
import Settings from 'pages/Settings';
import MainLayout from 'components/MainLayout';
import SignupLayout from 'components/SignupLayout';
import Documents from 'pages/Documents';
import { NotificationContextProvider } from 'components/Notification';
import Login from 'pages/auth/Login';
import ResetPassword from 'pages/auth/ResetPassword';
import EmailReset from 'pages/auth/EmailReset';
import ForgotPassword from 'pages/auth/ForgotPassword';
import Service from 'pages/Service';
import PatientInvite from 'pages/auth/PatientInvite';
import Personal from 'pages/signup/Personal';
import Email from 'pages/signup/Email';
import useSessionStorage from 'hooks/useSessionStorage';
import Fragebogen from 'pages/Fragebogen';
import HeyflowLayout from 'components/Onboarding/HeyflowLayout';
import EterminLayout from 'components/Onboarding/EterminLayout';
import Termin from 'pages/Termin';
import RegisterV2 from 'pages/Register-v2';
import { BookingUrl } from './providers/BookingUrl';
import ResendActivationEmail from './pages/auth/ResendActivationEmail';
import Layout from './pages/auth/Layout';
import RegistrationComplete from './pages/signup/RegistrationComplete';

function IndexRedirect() {
  const auth = useAuthContext();
  if (auth.isLoading) {
    return null;
  }

  if (auth.isAuthenticated) {
    return <Navigate to={'/therapie'} />;
  }

  return <Navigate to={'/login'} />;
}

function SignupStepThreeOrRedirect() {
  const { getItem } = useSessionStorage();
  const userData = getItem('personal');

  if (!userData) {
    return <Navigate to={'/register'} />;
  }

  return (
    <SignupLayout>
      <Email />
    </SignupLayout>
  );
}

function App() {
  /**
   * The structure of these Routes may seem a little random at first, but:
   *
   * Routes such as /email-reset and /password-reset are entirely public
   * and use a ?token= query parameter. After making an API request with that token,
   * user data might change and a /users/me call would return updated data (specifically for the email change)
   *
   * Mounting these routes underneath the same AuthProvider would result in a race condition.
   * The /users/me call would be made immediately on app load and return the OLD e-mail address.
   * Simply keeping url token based routes out of the AuthProvider fixes all this and removes
   * the need for making a second /users/me call to get the correct data.
   *
   * The /login route remains underneath the AuthProvider, because it provides a login() function
   * that is used by the /login route and abstracts the storing/validating of the authenticated JWT
   */
  return (
    <NotificationContextProvider>
      <BookingUrl>
        <Routes>
          <Route path="/email-bestaetigen" element={<EmailReset />} />
          <Route path="/passwort-vergessen" element={<ForgotPassword />} />
          <Route path="/patienten-einladen" element={<PatientInvite />} />
          <Route
            path="*"
            element={
              <AuthProvider>
                <ScrollToTop>
                  <Routes>
                    <Route path="/" element={<IndexRedirect />} />
                    <Route path="/passwort-setzen" element={<ResetPassword newUser />} />
                    <Route
                      path="/passwort-zuruecksetzen"
                      element={<ResetPassword newUser={false} />}
                    />
                    <Route path="/aktivierung-erneut-senden" element={<ResendActivationEmail />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/therapie/*"
                      element={
                        <MainLayout title="Ihr Weg zur persönlichen Cannabistherapie">
                          <Therapy />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/einstellungen/*"
                      element={
                        <MainLayout title="Einstellungen">
                          <Settings />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/dokumente"
                      element={
                        <MainLayout title="Dokumente">
                          <Documents />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/service"
                      element={
                        <MainLayout title="Service">
                          <Service />
                        </MainLayout>
                      }
                    />
                    <Route path="/register" element={<Navigate to="/register/personal" />} />
                    <Route
                      path="/register/personal"
                      element={
                        <SignupLayout>
                          <Personal />
                        </SignupLayout>
                      }
                    />
                    <Route path="/register/email" element={<SignupStepThreeOrRedirect />} />
                    <Route
                      path="/register/complete"
                      element={
                        <Layout>
                          <RegistrationComplete />
                        </Layout>
                      }
                    />
                    <Route
                      path="/register-v2"
                      element={
                        <SignupLayout>
                          <RegisterV2 />
                        </SignupLayout>
                      }
                    />
                    <Route
                      path="/fragebogen"
                      element={
                        <HeyflowLayout>
                          <Fragebogen />
                        </HeyflowLayout>
                      }
                    />
                    <Route
                      path="/termin"
                      element={
                        <EterminLayout>
                          <Termin />
                        </EterminLayout>
                      }
                    />
                  </Routes>
                </ScrollToTop>
              </AuthProvider>
            }
          />
        </Routes>
      </BookingUrl>
    </NotificationContextProvider>
  );
}

export default App;
