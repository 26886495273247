import { useState } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as MenuIcon } from 'assets/icons/hamburger.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg';
import { ReactComponent as SpeechBubbleIcon } from 'assets/icons/speech_bubble.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import MobileMenu from './MobileMenu';
import { useNotificationContext } from 'components/Notification';
import { PatientName } from './usePatient';
import UserIcon from './UserIcon';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_left.svg';
import { useOnboardingNavigation } from 'providers/onboarding-navigation';
import useLocalizedImages from '../../hooks/useLocalizedImages';
import DiscountBanner from '../DiscountBanner';

interface HeaderProps {
  title?: string;
  isOnboarding?: boolean;
  simpleNavigation?: boolean;
  isFragebogen?: boolean;
}

const tabs = [
  {
    title: 'Therapie',
    url: '/therapie/verschreibungen',
    Icon: CalendarIcon,
  },
  {
    title: 'Dokumente',
    url: '/dokumente',
    Icon: DocumentIcon,
  },
  {
    title: 'Service',
    url: '/service',
    Icon: SpeechBubbleIcon,
  },
  {
    title: 'Einstellungen',
    url: '/einstellungen',
    Icon: SettingsIcon,
  },
];

export default function Header({
  isOnboarding = false,
  simpleNavigation = false,
  isFragebogen = false,
}: HeaderProps) {
  const [showMenu, setShowMenu] = useState(false);
  const { NotificationOutlet } = useNotificationContext();
  const { step, setStep } = useOnboardingNavigation();
  const { logo } = useLocalizedImages();
  const location = useLocation();
  const isOnInfosheetStep = step === 7;
  const isLoadingFragebogen = step === -1;
  const position = isOnboarding ? 'fixed' : 'sticky';

  const renderBackBtn = () => {
    if (!isOnboarding) {
      return;
    }
    if (isFragebogen && (isLoadingFragebogen || isOnInfosheetStep)) return;

    if (simpleNavigation) {
      return (
        <button
          onClick={() => setStep(step > 0 ? step - 1 : 0)}
          className="hidden xl:flex items-center absolute left-10 top-[50%] translate-y-[-50%]"
        >
          <ArrowIcon />
          <span className="text-swopa-primary-dark-blue ml-2">Zurück</span>
        </button>
      );
    } else {
      return (
        <button className="hidden xl:flex items-center absolute left-10 top-[50%] translate-y-[-50%]">
          <ArrowIcon />
          <Link to="/therapie" className="text-swopa-primary-dark-blue ml-2">
            Zurück
          </Link>
        </button>
      );
    }
  };

  return (
    <>
  <div
    className={classNames(position, 'w-screen xl:w-full top-0 z-40 xl:relative', {
      'xl:border-b xl:border-[#E5E7EB]': isOnboarding,
    })}
  >
    <DiscountBanner />
    <header
      className={classNames('flex flex-col backdrop-blur-md bg-white/90 xl:bg-inherit xl:py-10')}
    >
      {/* user icon */}
      <NavLink
        to="/einstellungen"
        className="hidden xl:inline-flex self-end justify-end items-center text-swopa-primary-dark-blue pr-10 relative"
      >
          <span className="mr-3 text-[12px]">
            <PatientName />
          </span>
        <UserIcon />
        </NavLink>
        {renderBackBtn()}
        {/* mobile header  */}
        <div className="flex xl:hidden justify-between items-center my-6 mx-4 md:mx-[35px] xl:mx-20">
          <MenuIcon
            onClick={() => setShowMenu((prev) => !prev)}
            className="text-swopa-primary-dark-blue cursor-pointer"
          />
          <Link to="/therapie">
            <img src={logo} alt="logo" className="w-[84px]" />
          </Link>
          <NavLink to="/einstellungen">
            <UserIcon size="small" />
          </NavLink>
        </div>

        {/* mobile tabs */}
        {!isOnboarding && (
          <div
            className="flex overflow-x-scroll overflow-y-hidden w-screen flex-nowrap no-scrollbar md:hidden -mb-[1px] -mt-4">
            {tabs.map(({ title, url, Icon }, index) => {
              const active = location.pathname === url;
              return (
                <NavLink
                  key={index}
                  to={url}
                  className={classNames(
                    'inline-flex items-center justify-center p-3 border-b-2 border-transparent text-swopa-primary-dark-blue first-of-type:ml-4 last-of-type:mr-4',
                    {
                      'text-swopa-accent-green': active,
                      'border-b-swopa-accent-green': active,
                    },
                  )}
                >
                  <Icon className="mr-3 w-4 h-4" /> <span>{title}</span>
                </NavLink>
              );
            })}
          </div>
        )}

        {/* @ts-ignore */}
        {showMenu && <MobileMenu close={() => setShowMenu(false)} />}
      </header>

      <NotificationOutlet />

      <hr className="bg-swopa-border xl:hidden" />
    </div>

    <DiscountBanner mobileVersion={true} />
  </>
  );
}
