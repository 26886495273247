import type { CountryCode } from '../localizations/types';

interface DiscountBannerProps {
  mobileVersion?: boolean;
}


export default function DiscountBanner({ mobileVersion = false }: DiscountBannerProps) {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;

  if (LOCALIZATION !== 'DE') {
    return <></>;
  }

  if (mobileVersion === true) {
    return (
      <>
        <div className="block sm:hidden text-sm text-center w-full bg-swopa-background-beige py-2">
          <p>
            <b>Exklusives Dezember-Angebot:</b>
          </p>
          <p>
            Ihr Cannabistherapie-Termin für nur 5€
          </p>
          <p>
            nur bis zum 31.12.! Jetzt buchen!
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="hidden sm:flex w-full bg-swopa-background-beige py-2 items-center justify-center">
      <p className="text-center text-sm">
        <b>Exklusives Dezember-Angebot:</b> Ihr Cannabistherapie-Termin für nur 5€ – nur bis zum 31.12.! Jetzt buchen!
      </p>
    </div>
  );

}
