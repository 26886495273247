import { useNavigate } from 'react-router-dom';

/**
 * Component to show information about account deletion.
 * Currently deletion is not supported direclty, only through sending an email
 * @returns ReactElement
 */
export default function DeleteAccount() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="text-swopa-secondary-grey  cursor-pointer flex flex-row items-center mb-6"
        onClick={() => navigate('../')}
      >
        <span className="flex w-[9px] h-[9px] border-l-[1px] border-b-[1px] border-swopa-secondary-grey rotate-45 mr-2" />
        <span>Zurück</span>
      </div>
      <h2 className="mb-9">Konto löschen</h2>
      <p className="text-swopa-primary-dark-blue w-[480px]">
        Wenn Sie Ihre Daten löschen möchten, dann schreiben Sie einfach eine E-Mail an folgende
        Adresse:
      </p>
      <br />
      <a className="underline text-swopa-primary-dark-blue" href="mailto:info@enmedify.com">
        info@enmedify.com
      </a>
    </>
  );
}
