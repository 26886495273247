import Box from 'styles/Box';
import { ReactComponent as CalendarIcon } from 'assets/icons/prescription_date_calendar.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as TrashcanIcon } from 'assets/icons/trashcan.svg';
import { ReactComponent as DoctorIcon } from 'assets/icons/doctor.svg';
import { ReactComponent as CheckMark } from 'assets/icons/checkmark.svg';
import { format } from 'date-fns';
import UserIcon from 'components/Navigation/UserIcon';
import { MouseEvent, useRef, useState, ChangeEvent, useEffect } from 'react';
import classNames from 'classnames';
import DocumentCategorySelect from './DocumentCategorySelect';
import http from 'components/util/http';
import Spinner from 'components/Spinner';

interface DocumentProps {
  filename: string;
  filetype: string;
  size?: string;
  uploadDate: Date;
  downloadLink: string;
  uploadedBy: 'self' | 'doctor';
  deleteCallback?: () => void;
  category?: string;
  documentId: number;
}

const fileCategories = [
  { label: 'Kategorie auswählen', value: '' },
  { label: 'Arztbrief stationär', value: 'doctor_letter_inpatient' },
  { label: 'Arztbrief ambulant', value: 'doctor_letter_outpatient' },
  { label: 'Gutachten', value: 'assessment' },
  { label: 'Rechnung', value: 'bill' },
  { label: 'Rezept', value: 'recipe' },
  { label: 'Überweisung', value: 'transfer' },
  { label: 'Datenschutzerklärung', value: 'privacy_policy' },
  { label: 'Datenverarbeitungsvereinbarung', value: 'data_processing_agreement' },
  { label: 'AGB', value: 'agb' },
  { label: 'Aufklärung', value: 'enlightenment' },
  { label: 'Information über privatärztliche Behandlung', value: 'private_medical_treatment' },
  { label: 'Personalausweis', value: 'id' },
  { label: 'Reisepass', value: 'passport' },
  { label: 'Krankenkassenkarte', value: 'health_insurance_card' },
  { label: 'Sonstiges', value: 'miscellaneous' },
];

export default function Document({
  filename,
  filetype,
  size,
  uploadDate,
  downloadLink,
  deleteCallback,
  uploadedBy,
  category = '',
  documentId,
}: DocumentProps) {
  const [selectedCategory, setSelectedCategory] = useState(category);
  const selectWrapperRef = useRef() as any;
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(true);

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    deleteCallback && deleteCallback();
  };

  const handleDownload = (e: MouseEvent) => {
    if (e.target === selectWrapperRef.current || selectWrapperRef.current.contains(e.target))
      return;
    window.open(downloadLink);
  };

  const handleSelectCategory = async (e: ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;
    setSelectedCategory(category);
    try {
      setLoading(true);
      setFinished(false);
      await http.patch(`/medical/documents/${documentId}/`, {
        content_type: category,
      });
    } catch (error: any) {
      // TODO: handle errors
      setFinished(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => setFinished(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  return (
    <Box className="cursor-pointer transition-shadow hover:shadow-sm" onClick={handleDownload}>
      <div className="flex flex-col sm:flex-row justify-between">
        <div
          className="flex justify-between items-center px-3 pb-0 py-3 sm:px-4 sm:pb-3 cursor-default flex-grow-0"
          ref={selectWrapperRef}
        >
          <>
            <div className="flex flex-col sm:flex-row w-full">
              <DocumentCategorySelect
                options={fileCategories}
                selected={selectedCategory}
                handleChange={handleSelectCategory}
                name="filetype_select"
                disabled={uploadedBy !== 'self'}
              />
              <div className="flex items-center sm:ml-2">
                <span className="text-xs text-swopa-secondary-grey">{filename}</span>
                {loading ? (
                  <Spinner className="swopa-grey-2 w-3 h-3 ml-2" />
                ) : (
                  <>{!finished && <CheckMark className="fill-white w-3 h-3 ml-2" />}</>
                )}
              </div>
            </div>
            {size && <span className="text-swopa-secondary-grey text-documents-info">{size}</span>}
          </>
        </div>
        <div className="flex sm:w-[340px]">
          <div className="flex items-center text-swopa-primary-dark-blue p-3">
            {uploadedBy === 'self' ? (
              <UserIcon size="small" className="!mr-0" />
            ) : (
              <UserIcon size="small" className="!mr-0">
                <DoctorIcon className="text-white" />
              </UserIcon>
            )}
          </div>
          <div className="flex items-center text-swopa-primary-dark-blue p-3">
            <CalendarIcon className="stroke-swopa-primary-dark-blue mr-2" />
            <span>{format(uploadDate, 'dd.MM.yyyy')}</span>
          </div>
          <div className="flex items-center text-swopa-primary-dark-blue p-3">
            <FileIcon className="mr-2" />
            <span className="uppercase">{filetype}</span>
          </div>
          <div className="flex pl-3 pr-6 py-3">
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              download={filename}
              className="text-swopa-primary-dark-blue flex items-center transition-colors hover:text-swopa-secondary-light-blue-hover mr-4"
            >
              <DownloadIcon />
            </a>
            {uploadedBy === 'self' && (
              <button
                className={classNames(
                  'text-swopa-primary-dark-blue transition-colors hover:text-swopa-warning-red',
                  `${deleteCallback ? 'cursor-pointer' : 'text-swopa-secondary-grey'}`,
                )}
                onClick={handleDelete}
              >
                <TrashcanIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
}
