import ch_flag from '../assets/images/flags/CH.svg';
import de_flag from '../assets/images/flags/DE.svg';
import { CountryDialogLocalizations } from './types';

const countryDialogs: CountryDialogLocalizations = {
  other_country: {
    CH: {
      title: 'Willkommen! Du scheinst uns von außerhalb Deutschlands zu besuchen',
      body: 'Du besuchst uns offenbar von außerhalb der Länder, in denen wir bisher vertreten sind - Deutschland und die Schweiz',
      buttons: [{
        text: 'Ich verstehe, ich möchte auf der schweizer Website bleiben',
        icon: ch_flag,
        redirection: 'CH',
      },
      {
        text: 'Gehe zur deutschen Website',
        icon: de_flag,
        redirection: 'DE',
      }]
    },
    DE: {
      title: 'Willkommen! Du scheinst uns von außerhalb Deutschlands zu besuchen',
      body: 'Du besuchst uns offenbar von außerhalb der Länder, in denen wir bisher vertreten sind - Deutschland und die Schweiz',
      buttons: [{
        text: 'Ich verstehe, ich möchte auf der deutschen Website bleiben',
        icon: de_flag,
        redirection: 'DE'
      },
      {
        text: 'Gehe zur schweizer Website',
        icon: ch_flag,
        redirection: 'CH'
      }]
    },
  },
  incorrect_country: {
    CH: {
      title: 'Anscheinend besuchst Du uns von Deutschland aus!',
      body: 'Unsere Inhalte und Services sind für jedes Land individuell angepasst. Du befindest Dich momentan auf der schweizer Website. Möchtest Du zur deutschen Seite wechseln, um die für Dich relevanten Informationen und Angebote zu sehen?',
      buttons: [{
        text: 'Gehe zur deutschen Website',
        icon: de_flag,
        redirection: 'DE'
      },
      {
        text: 'Bleibe auf der schweizer Website',
        icon: ch_flag,
        redirection: 'CH'
      }]
    },
    DE: {
      title: 'Anscheinend besuchst Du uns aus der Schweiz!',
      body: 'Unsere Inhalte und Services sind für jedes Land individuell angepasst. Du befindest Dich momentan auf der deutschen Website. Möchtest Du zur Schweizer Seite wechseln, um die für Dich relevanten Informationen und Angebote zu sehen?',
      buttons: [{
        text: 'Gehe zur schweizer Website',
        icon: ch_flag,
        redirection: 'CH'
      },
      {
        text: 'Bleibe auf der deutschen Website',
        icon: de_flag,
        redirection: 'DE'
      }]
    },
  }
};

export default countryDialogs;
