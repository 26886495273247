import Sidebar from './Signup/Sidebar';
import Header from './Signup/Header';
import { useNotificationContext } from 'components/Notification';

interface SignupLayoutProps {
  children: React.ReactNode;
}

export default function SignupLayout({ children }: SignupLayoutProps) {
  const { NotificationOutlet } = useNotificationContext();
  return (
    <>
      <NotificationOutlet />
      <div className="flex w-screen lg:flex-row flex-col lg:h-screen overflow-x-hidden bg-white">
        <div className="px-6 m-auto lg:w-[70%] lg:overflow-y-auto lg:h-screen">
          <Header />
          {children}
        </div>
        <Sidebar />
      </div>
    </>
  );
}
