import { Dialog } from "@headlessui/react"
import { useState } from "react"
import useCountrySelect, { Country } from "./useCountrySelect";
import Button from "styles/Button";
import { useLocalizedIncorrectCountryDialog, useLocalizedOtherCountryDialog } from "hooks/useLocalizedCountryDialog";
import { CountryCode } from "localizations/types";

type DialogButtonProps = {
  title: string;
  onClick: () => void;
  icon?: string;
}

function DialogButton({title, onClick, icon: img}: DialogButtonProps) {
  return (
    <Button className="uppercase rounded-full mb-5 bg-gray-200 text-gray-800" onClick={onClick}>
      <div className="flex flex-row justify-center">
        {img ? <img alt="" src={img} className="h-4 w-4 mr-2 mt-1"/> : <></>}
        {title}
      </div>
    </Button>
  );
}

export function IncorrectCountryDialog() {
  const [isOpen, setIsOpen] = useState(true);
  const countrySelect = useCountrySelect();
  const localizations = useLocalizedIncorrectCountryDialog();

  const handleOnClick = (redirection: CountryCode | undefined) => {
    if(redirection){
      countrySelect.redirect(redirection);
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 bg-black bg-opacity-70 z-50"></div>
      <Dialog.Panel className="fixed z-60 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white shadow-xl p-6 rounded-xl flex flex-col items-center">
        <Dialog.Title className="text-xl mb-5">{localizations.title}</Dialog.Title>
        <Dialog.Description className="mb-5 text-gray-400">
          {localizations.body}
        </Dialog.Description>
        <div className="flex flex-col items-center">
          {localizations.buttons.map(b => (
            <DialogButton key={b.text} title={b.text} icon={b.icon} onClick={() => handleOnClick(b.redirection)}/>
          ))}
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}

export function OtherCountryDialog() {
  const [isOpen, setIsOpen] = useState(true);
  const countrySelect = useCountrySelect();
  const localizations = useLocalizedOtherCountryDialog();

  const handleOnClick = (redirection: CountryCode | undefined) => {
    if(redirection){
      countrySelect.redirect(redirection);
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 bg-black bg-opacity-70 z-50"></div>
      <Dialog.Panel className="fixed z-60 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white shadow-xl p-6 rounded-xl flex flex-col items-center">
        <Dialog.Title className="text-xl mb-5">{localizations.title}</Dialog.Title>
        <Dialog.Description className="mb-5 text-gray-400">
          {localizations.body}
        </Dialog.Description>
        <div className="flex flex-col items-center">
          {localizations.buttons.map(b => (
            <DialogButton key={b.text} title={b.text} icon={b.icon} onClick={() => handleOnClick(b.redirection)}/>
          ))}
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}